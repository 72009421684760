import React from 'react';
import { useTranslation } from 'react-i18next';
import { imageAbsolutePath } from '../../../../utils/utils';
import TitleH3 from '../../../../ui-components/TitleH3/TitleH3';
import { LinkWrapper } from '../../../../ui-components/LinkWrapper/LinkWrapper';
import styles from './BPTSection.module.css';
const BPTAllIcon = imageAbsolutePath('newCategories/bpt_all.png');
const CIcon = imageAbsolutePath('newCategories/c.png');
const C1Icon = imageAbsolutePath('newCategories/c1.png');
const CZVAll = imageAbsolutePath('newCategories/czv-all.png');
const D = imageAbsolutePath('newCategories/d.png');
const D1 = imageAbsolutePath('newCategories/d1.png');
const BPTSection: React.FC = () => {
  const { t } = useTranslation();
  return (
    <section>
      <div className={styles.container}>
        <TitleH3 center className={styles.upTitle} text={'Coming soon'} />
        <TitleH3 center className={styles.title} text={t('homePageNew.bptSection.title')} />
        <h5>{t('homePageNew.bptSection.secondTitle')}</h5>
        <div className={styles.imageContainer}>
          <div className={styles.imagesWrapper}>
            <img src={BPTAllIcon} alt={'Bpt All'} />
            <img src={C1Icon} alt={'C1'} />
            <img src={CIcon} alt={'C'} />
            <img src={D1} alt={'D1'} />
            <img src={D} alt={'D'} />
            <img src={CZVAll} alt={'CZV All'} />
          </div>
          <LinkWrapper className={styles.button} to={''}>
            {t('homePageNew.bptSection.buttonText')}
          </LinkWrapper>
        </div>
      </div>
    </section>
  );
};
export default BPTSection;
